/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable lines-between-class-members */

import React from 'react';
import styled from 'styled-components';
import axios from 'axios';

import SEO from 'components/SEO';

async function send(values) {
  await axios({
    method: 'post',
    url: '/functions/mail.php',
    data: values,
  });
}

const Form = styled.form`
  position: relative;
`;

const SendingOverlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.75);
`;

const SendingMessage = styled.div`
  padding: 20px;
  font-weight: bold;
  font-size: 2em;
`;

class ContactForm extends React.Component {
  state = {
    sending: false,
    sent: false,
    failed: false,
    validationError: null,
  };

  autoValidate = false;

  fields = {
    name: {
      required: true,
    },
    email: {
      required: true,
      validator: value => /.+@.+\.+/.test(value),
    },
    subject: {
      required: true,
    },
    message: {
      required: true,
    },
  };

  values = {};

  handleChange = (fieldName, ev) => {
    this.values[fieldName] = ev.target.value;
    if (this.autoValidate) {
      this.validate();
    }
  }

  handleSubmit = (ev) => {
    ev.preventDefault();
    const isValid = this.validate();
    if (!isValid) {
      this.autoValidate = true;
      return;
    }

    this.sendMessage();
  };

  async sendMessage() {
    this.setState({
      sending: true,
    });

    try {
      await send(this.values);

      this.setState({
        sent: true,
      });
    } catch (err) {
      this.setState({
        sending: false,
        failed: true,
      });
    }
  }

  validate() {
    const fieldNames = Object.keys(this.fields);

    const complete = fieldNames.every((key) => {
      const field = this.fields[key];
      const value = this.values[key];

      return !field.required || !!value;
    });

    if (!complete) {
      this.setState({
        validationError: 'incomplete',
      });
      return false;
    }

    if (!this.fields.email.validator(this.values.email)) {
      this.setState({
        validationError: 'invalid-email',
      });
      return false;
    }

    this.autoValidate = false;

    this.setState({
      validationError: null,
    });
    return true;
  }

  renderSendingOverlay() {
    const { sending } = this.state;

    return sending && (
      <SendingOverlay>
        <SendingMessage>
          Wird gesendet...
        </SendingMessage>
      </SendingOverlay>
    );
  }

  renderValidationError() {
    const { validationError } = this.state;
    if (!validationError) {
      return null;
    }

    const getMessage = (error) => {
      switch (error) {
        case 'incomplete':
          return 'Bitte füllen Sie alle Felder aus.';
        case 'invalid-email':
          return 'Bitte überprüfen Sie Ihre Email-Adresse.';
        default:
          return 'Unbekannter Validierungsfehler';
      }
    };

    return (
      <p className="error">
        {getMessage(validationError)}
      </p>
    );
  }

  // eslint-disable-next-line class-methods-use-this
  renderSent() {
    return (
      <div>
        <p>
          <strong>Vielen Dank!</strong>
        </p>
        <p>
Die Email wurde erfolgreich versendet. Wir werden schnellstmöglich mit Ihnen in
Kontakt treten.
        </p>
      </div>
    );
  }

  render() {
    const {
      sent,
      sending,
      failed,
    } = this.state;

    if (sent) {
      return this.renderSent();
    }

    const formDisabled = sending;

    return (
      <Form
        id="contact-form"
        disabled={formDisabled}
        onSubmit={this.handleSubmit}
      >
        {this.renderValidationError()}
        {failed && (
          <p className="error">
            Der Versand ist fehlgeschlagen. Bitte versuchen Sie es erneut.
          </p>
        )}
        <p>
          <label htmlFor="contact-name">Ihr Name: </label>
          <input
            type="text"
            id="contact-name"
            name="name"
            className="std"
            onChange={(ev) => { this.handleChange('name', ev); }}
          />
        </p>
        <p>
          <label htmlFor="contact-from">Ihre Email: </label>
          <input
            type="text"
            id="contact-from"
            name="from"
            className="std"
            onChange={(ev) => { this.handleChange('email', ev); }}
          />
        </p>
        <p>
          <label htmlFor="contact-subject">Betreff: </label>
          <input
            type="text"
            id="contact-subject"
            name="subject"
            className="std"
            onChange={(ev) => { this.handleChange('subject', ev); }}
          />
        </p>
        <p className="freetext">
          <label htmlFor="contact-message">Nachricht: </label>
          <textarea
            id="contact-message"
            name="message"
            cols="32"
            rows="8"
            onChange={(ev) => { this.handleChange('message', ev); }}
          />
        </p>
        <p className="buttons">
          <button type="submit">Absenden</button>
        </p>
        {this.renderSendingOverlay()}
      </Form>
    );
  }
}

const KontaktPage = () => (
  <>
    <SEO title="Kontakt" keywords={[]} />
    <h1>Kontaktieren Sie uns per Email</h1>
    <ContactForm />

    <h1>Postanschrift</h1>

    <div id="hcard-jomag-ag" className="postaladdress vcard">
      <div className="fn" style={{ display: 'none' }}>Erich Mathys</div>
      <a className="url org" href="http://jomagag.ch/">Jomag AG</a>

      <p className="adr">
        <span className="street-address">Stutzackerweg 2</span>
        <br />
        <span className="postal-code">3110</span>
        {' '}
        <span className="locality">Münsingen</span>
        <span style={{ display: 'none' }}>
,
          <span className="region">Bern</span>
        </span>
        <span className="country-name" style={{ display: 'none' }}>Switzerland</span>
      </p>
      <p>
        <span className="tel">+41&nbsp;31&nbsp;722&nbsp;0706</span>
      </p>
    </div>

  </>
);

export default KontaktPage;
